'use client';
import { Suspense } from 'react';
import LoginForm from './forms/LoginForm';
import SignupForm from './forms/Signup/SignupForm';

interface Props {
  variant: 'login' | 'signup';
}

const LoginOrSignupPage = ({ variant }: Props) => {
  return (
    <div className="flex h-full w-full justify-center pt-16 sm:items-center sm:pt-0">
      <Suspense>
        {variant === 'login' ? <LoginForm /> : <SignupForm />}
      </Suspense>
    </div>
  );
};

export default LoginOrSignupPage;
