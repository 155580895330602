'use client';
import SocialLoginButtons from '@c/SocialLoginButton';
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Transition,
} from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import LinkTargetBlank from '@ui/Link/LinkTargetBlank';
import { logEvent } from '@util/analytics';
import {
  createUserDocument,
  getPublicUserDoc,
  getUserByPhone,
  getValidUserDocument,
} from '@util/firestore/users';
import { formatAuthError } from '@util/index';
import { logError } from '@util/logError';
import AuthProvider, { initAuthProvider, useAuth } from 'context/AuthContext';
import { FirebaseError } from 'firebase/app';
import { motion } from 'framer-motion';
import { CountryCode, ParseError, parsePhoneNumber } from 'libphonenumber-js';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next-nprogress-bar';
import { useEffect, useState } from 'react';
import { LoginCountryDropdown } from './CountryDropdown';
import UsernameForm from './UsernameForm';
import { AUTH } from '@util/firebase';
import { getRedirectResult, UserCredential } from 'firebase/auth';
import LoadingSpinner from '@c/LoadingSpinner';
import { dialCountries } from 'features/contryDropdown/lib/countries';
import { usePhoneOTP } from 'features/phoneOTP/hooks/usePhoneOTP';
import Image from 'next/image';
import PhoneOTP from 'features/phoneOTP';
import MXPhone from './assets/mx-phone.svg';
import AuthFormInput from './components/AuthFormInput';

const AuthForm = ({
  continueFn,
}: {
  continueFn: (uid: string, firstName: string, isSocial: boolean) => void;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();
  const {
    loginEmailPassword,
    loginSocialRedirect,
    loginSocial,
    fetchProvidersForEmail,
    sendPhoneVerification,
    signInWithPhone,
    attribution,
  } = useAuth();
  const [authError, setAuthError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previousLoginMethod, setPreviousLoginMethod] = useState<
    'email' | 'phone' | ''
  >('phone');

  useEffect(() => {
    const storedMethod =
      typeof window !== 'undefined'
        ? (localStorage.getItem('loginMethod') as 'email' | 'phone' | '')
        : null;
    if (storedMethod) {
      setPreviousLoginMethod(storedMethod);
    }
  }, []);

  const [loginType, setLoginType] = useState<'email' | 'phone' | ''>(
    previousLoginMethod ?? 'phone'
  );
  const [phoneStep, setPhoneStep] = useState<'phone' | 'code' | ''>('phone');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);
  const [fetchingProviders, setFetchingProviders] = useState(false);
  const [code, setCode] = useState('');
  const [verificationId, setVerificationId] = useState<string>('');
  const [providers, setProviders] = useState<string[]>([]);
  const [countryCode, setCountryCode] = useState<string>('US');
  const {
    otpDigits,
    setOtpDigits,
    codeOtpLoading,
    setCodeOtpLoading,
    timeToResend,
    setTimeToResend,
  } = usePhoneOTP();

  const handleSavePreviousLoginMethod = async () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('loginMethod', loginType);
    }
  };

  const [handlingRedirect, setHandlingRedirect] = useState(false);

  useEffect(() => {
    if (handlingRedirect) return;

    const handleRedirectSuccess = async (result: UserCredential) => {
      setHandlingRedirect(true);
      const user = result.user;
      const method = result.providerId;

      const userDoc = await getPublicUserDoc({ uid: user.uid });

      const redirect = searchParams?.get('redirect');
      let defaultRedirectPath = '/';

      if (!userDoc) {
        const docData = getValidUserDocument(
          user.uid,
          user.email ?? '',
          {
            name: user.displayName ?? 'Anonymous User',
            opted_out: false,
          },
          attribution
        );
        await createUserDocument(docData, method as AuthProvider);
        logEvent('sign_up', { method });
        continueFn(user.uid, user.displayName?.split(' ')?.[0] ?? '', true);
        queryClient.invalidateQueries({
          queryKey: ['authUser'],
        });
        router.push(redirect ? redirect : defaultRedirectPath);
        return;
      }

      console.log(
        'Existing user logging in, redirecting to:',
        redirect || defaultRedirectPath
      );

      logEvent('login', { method });
      // Check if user has a complete account
      const phoneNumber = user.phoneNumber;
      if (!phoneNumber || phoneNumber === '') {
        router.push('/signup?step=0');
        return;
      } else {
        router.push(redirect ?? '/');
        return;
      }
    };

    getRedirectResult(AUTH)
      .then((result) => {
        if (result) handleRedirectSuccess(result);
      })
      .catch((error) => {
        logError(error);
        setAuthError(
          error instanceof FirebaseError
            ? formatAuthError(error)
            : 'An error occurred during authentication. Please try again.'
        );
      });
  }, [
    handlingRedirect,
    searchParams,
    router,
    queryClient,
    attribution,
    continueFn,
  ]);

  const login = async () => {
    setIsSubmitting(true);
    const loginResp = await loginEmailPassword({
      email,
      password,
    });
    if (loginResp instanceof FirebaseError) {
      if (loginResp.code === 'auth/wrong-password') {
        const providers = await fetchProvidersForEmail(email);
        if (
          Array.isArray(providers) &&
          providers.length &&
          !providers.includes('your email and password')
        ) {
          const str = providers.join(' or ');
          setAuthError(
            `This account was signed up with ${str}. Please sign in with that provider.`
          );
          setIsSubmitting(false);
          return;
        } else {
          setIsSubmitting(false);
          setAuthError(formatAuthError(loginResp));
        }
      }
      setIsSubmitting(false);
      setAuthError(formatAuthError(loginResp));
    } else {
      logEvent('login', { method: 'email' });
      const redirect = searchParams?.get('redirect');
      router.push(redirect ? redirect : '/');
      handleSavePreviousLoginMethod();
    }
  };

  const loginWithPhone = async (code: string) => {
    setIsSubmitting(true);
    try {
      const loginResp = await signInWithPhone(verificationId, code);
      if (loginResp instanceof FirebaseError) {
        logError(loginResp);
        setIsSubmitting(false);
        if (loginResp.code === 'auth/invalid-verification-code') {
          setAuthError('Invalid verification code. Please try again.');
          setOtpDigits([]);
          setTimeout(() => {
            document.getElementById('otp-0')?.focus();
          }, 100);
        } else {
          setAuthError(formatAuthError(loginResp));
        }
      } else {
        logEvent('login', { method: 'phone' });
        const redirect = searchParams?.get('redirect');
        router.push(redirect ? redirect : '/');
        handleSavePreviousLoginMethod();
      }
    } catch (e) {
      setIsSubmitting(false);
      logError(e);
      if (
        e instanceof FirebaseError &&
        e.code === 'auth/invalid-verification-code'
      ) {
        setAuthError('Invalid verification code. Please try again.');
        setOtpDigits([]);
        setTimeout(() => {
          document.getElementById('otp-0')?.focus();
        }, 100);
      } else {
        setAuthError('An error occurred. Please try again or contact support.');
      }
    }
  };

  const handleSocialClick = async (authProvider: AuthProvider) => {
    try {
      const res = await loginSocial(authProvider);

      if (res instanceof FirebaseError) {
        setAuthError(formatAuthError(res));
      } else {
        try {
          // if this succeeds and returns a user, user already exists
          const userDoc = getValidUserDocument(
            res.user.uid,
            res.user.email ?? '',
            {
              name: res.user.displayName ?? 'Anonymous',
              opted_out: false,
            },
            attribution
          );

          if (userDoc) {
            const redirect = searchParams?.get('redirect');

            const phoneNumber = res.user.phoneNumber;
            if (!phoneNumber || phoneNumber === '') {
              router.push('/signup?step=0');
              return;
            } else {
              router.push(redirect ?? '/');
              return;
            }
          } else {
            const docData = getValidUserDocument(
              res.user.uid,
              res.user.email ?? '',
              {
                name: res.user.displayName ?? 'Anonymous',
                opted_out: false,
              },
              attribution
            );

            await createUserDocument(docData, authProvider);

            router.push('/signup?step=0');
            return;
          }
        } catch (e) {
          console.log('No user doc found, creating one now');
        }
      }
    } catch (e) {
      logError(e);
      setAuthError('An error occurred. Please try again.');
    }
  };

  const countryDialCode = dialCountries.find(
    (c) => c.code === countryCode
  )?.dial_code;

  const sendConfirmationCode = async () => {
    try {
      setSendingCode(true);
      const phoneNumber = parsePhoneNumber(phone, countryCode as CountryCode);
      const userExists = await getUserByPhone(phoneNumber.number);
      if (!userExists) {
        setSendingCode(false);
        setAuthError(
          'No account found with this phone number. Please sign up first or try logging in with your email.'
        );
        return;
      }
      setVerificationId(await sendPhoneVerification(phoneNumber.number));
      setPhoneStep('code');
      setTimeToResend(60);
    } catch (e) {
      logError(
        e,
        undefined,
        undefined,
        `send code: ${phone} + ${(e as Error).message}`
      );

      if (
        e instanceof FirebaseError &&
        e.code === 'auth/invalid-verification-code'
      ) {
        setAuthError('Invalid Confirmation Code');
      }

      if (e instanceof ParseError) {
        setAuthError('Invalid phone number');
      } else if (e instanceof FirebaseError) {
        setAuthError(e.message);
      } else {
        setAuthError('An error occurred. Please try again.');
      }
    } finally {
      setSendingCode(false);
    }
  };

  const submitConfirmationCode = async (
    newDigits: string[],
    i: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const allDigits = [...newDigits];
    allDigits[i] = e.target.value;
    if (allDigits.filter(Boolean).length === 6) {
      try {
        setIsSubmitting(true);
        setCodeOtpLoading(true);
        setAuthError(null);
        await loginWithPhone(allDigits.join(''));
      } catch (error) {
        logError(error);
        if (
          error instanceof FirebaseError &&
          error.code === 'auth/invalid-verification-code'
        ) {
          setAuthError('Invalid Confirmation Code');
          setOtpDigits([]);
          setTimeout(() => {
            document.getElementById('otp-0')?.focus();
          }, 100);
        } else {
          setAuthError('An error occurred. Please try again.');
        }
        setIsSubmitting(false);
      } finally {
        // temporarily keeping the isSubmiting true if there are no errors so the user will see the loading state until they are redirected.
        // setIsSubmitting(false);
        setCodeOtpLoading(false);
      }
    }
  };

  if (handlingRedirect) return <LoadingSpinner />;
  return (
    <div className="flex h-full max-h-[50rem] w-full flex-col items-start px-[2.4rem] pb-[3.2rem] sm:mt-[5rem] sm:p-0">
      {/* form */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-[50rem]"
      >
        {(phoneStep === 'phone' || loginType === 'email') &&
          phoneStep !== 'code' && (
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              className="text-[3rem] font-semibold sm:text-[4rem]"
            >
              Log in to MX Locker
            </motion.h1>
          )}

        {/* inputs and buttons */}

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]"
        >
          {authError && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="w-full text-center"
            >
              <div className="mx-auto w-fit justify-center rounded-[1rem] border bg-red-200 px-[1.6rem] py-[0.8rem] text-[1.6rem] font-medium text-red-900">
                {authError}
              </div>
            </motion.div>
          )}

          {loginType === 'phone' && phoneStep === 'phone' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <div className="flex gap-[1.6rem]">
                <LoginCountryDropdown
                  value={phone}
                  autoComplete="tel-national"
                  onChange={setPhone}
                  onCountryChange={setCountryCode}
                  countryCode={countryCode}
                />
              </div>

              {showCodeInput && (
                <motion.div
                  className="flex flex-col gap-[1.6rem]"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  transition={{ duration: 0.3 }}
                >
                  <AuthFormInput
                    label="Verification Code"
                    autoComplete="off"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </motion.div>
              )}

              {showCodeInput ? (
                <Button
                  text="Log In"
                  type="secondary"
                  onClick={async () => {
                    try {
                      setIsSubmitting(true);
                      await loginWithPhone(code);
                    } catch (e) {
                      logError(e);

                      setAuthError('An error occurred. Please try again.');
                    } finally {
                      setIsSubmitting(false);
                    }
                  }}
                  loading={isSubmitting}
                />
              ) : (
                <Button
                  text="Continue"
                  type="secondary"
                  loading={sendingCode}
                  className="mt-8 w-full !text-[2.1rem]"
                  onClick={sendConfirmationCode}
                />
              )}

              <div className="mb-2 mt-4 flex flex-col items-center gap-y-6">
                <div className="flex w-full justify-center">
                  <span className="text-[1.8rem] text-[#444444]">or</span>
                </div>

                <Button
                  text="Log in with email instead"
                  type="gray"
                  onClick={() => {
                    setLoginType('email');
                  }}
                  className="w-full !text-[1.8rem]"
                />
              </div>
            </motion.div>
          )}

          {loginType === 'phone' && phoneStep === 'code' && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="mx-[2rem] flex flex-col sm:mx-0"
            >
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <Image
                  src={MXPhone}
                  className="h-[10rem] w-[10rem]"
                  alt="MXLocker"
                />
              </motion.div>

              <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="text-[2.5rem] font-bold text-black sm:text-[3rem]"
              >
                Enter your verification code
              </motion.h1>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="flex items-center gap-x-4"
              >
                <span className="text-[1.8rem] text-[#444444]">
                  Sent to {countryDialCode} {phone}
                </span>

                <div className="h-2 w-2 rounded-full bg-[#D9D9D9]" />

                <button
                  type="button"
                  aria-label="Edit phone number"
                  className="text-[1.8rem] font-medium text-brand-secondary"
                  onClick={() => {
                    setLoginType('phone');
                    setPhoneStep('phone');
                  }}
                >
                  Edit
                </button>
              </motion.div>

              <PhoneOTP
                codeOtpLoading={codeOtpLoading}
                otpDigits={otpDigits}
                setOtpDigits={setOtpDigits}
                submitConfirmationCode={submitConfirmationCode}
                timeToResend={timeToResend}
                setTimeToResend={setTimeToResend}
                sendConfirmationCode={sendConfirmationCode}
                sendingCode={sendingCode}
              />
            </motion.div>
          )}

          {loginType === 'email' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="flex w-full flex-col gap-[1.6rem]"
            >
              <AuthFormInput
                label="Email"
                autoComplete="username"
                placeholder="Enter your email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <motion.div className="flex flex-col gap-[1.6rem]">
                <AuthFormInput
                  type="password"
                  label="Password"
                  autoComplete="current-password"
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </motion.div>

              <div className="flex w-full justify-end">
                <Link
                  href="/forgot-password"
                  className="text-right text-[1.8rem] font-semibold text-brand-secondary"
                >
                  Forgot Password?
                </Link>
              </div>

              <Button
                text="Log In"
                type="secondary"
                loading={isSubmitting}
                onClick={async () => {
                  try {
                    setIsSubmitting(true);
                    setFetchingProviders(true);
                    const providers = await fetchProvidersForEmail(
                      email.trim().toLowerCase()
                    );
                    if (
                      !providers ||
                      !Array.isArray(providers) ||
                      !providers.length
                    ) {
                      setAuthError(
                        'No account found with this email. Please sign up first or try logging in with your phone number.'
                      );
                    } else {
                      await login();
                    }
                  } catch (e) {
                    logError(e);
                    setAuthError((e as Error).message);
                  } finally {
                    setIsSubmitting(false);
                    setFetchingProviders(false);
                  }
                }}
                className="text-[1.8rem]"
              />

              {!!providers.filter((p) => p !== 'your email and password')
                .length && (
                <div className="w-full">
                  <div className="flex h-[2.4rem] justify-between">
                    <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
                    <p>You&apos;ve previously logged in with</p>
                    <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
                  </div>

                  <SocialLoginButtons
                    providers={providers}
                    handleSocialClick={handleSocialClick}
                  />
                </div>
              )}

              <div className="mb-4 flex flex-col items-center gap-y-6">
                <div className="flex w-full justify-center">
                  <span className="text-[1.8rem] text-[#444444]">
                    or continue with
                  </span>
                </div>

                <Button
                  text="Log in with phone instead"
                  type="gray"
                  onClick={() => {
                    setLoginType('phone');
                  }}
                  className="w-full !text-[1.8rem]"
                />
              </div>
            </motion.div>
          )}

          {/* divider */}
        </motion.div>
        {/* terms of use & privacy policy */}
        {phoneStep === 'phone' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            {!providers.length && (
              <SocialLoginButtons handleSocialClick={handleSocialClick} />
            )}
            <div className="mt-[4.8rem] flex w-full lg:mt-[6rem]">
              <p className="text-[1.8rem] text-[#444444]">
                New to MX Locker? &nbsp;
                <Link
                  href="/signup"
                  className="font-semibold text-brand-secondary"
                >
                  Sign Up
                </Link>
              </p>
            </div>
            <p className="mt-8 text-tos">
              By clicking sign in or continue with Facebook, Google or Apple,
              you agree to MX Locker&apos;s&nbsp;
              <LinkTargetBlank href="/terms-and-conditions">
                Terms of Use
              </LinkTargetBlank>
              &nbsp;and&nbsp;
              <LinkTargetBlank href="/privacy-policy">
                Privacy Policy
              </LinkTargetBlank>
              .
            </p>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

const LoginForm = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({
    uid: '',
    firstName: '',
    isSocial: false,
  });

  return (
    <TabGroup selectedIndex={tabIndex} onChange={setTabIndex}>
      <TabList className="hidden">
        <Tab></Tab>
        <Tab></Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <AuthForm
            continueFn={(uid, firstName, isSocial) => {
              setUserInfo({ uid, firstName, isSocial });
              setTabIndex(1);
            }}
          />
        </TabPanel>
        <TabPanel>
          <Transition
            appear
            show={tabIndex === 1}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <UsernameForm {...userInfo} />
          </Transition>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};
export default LoginForm;
